import lottie from 'lottie-web';

lottie.loadAnimation({
  container: document.getElementById('js-hero-animation'),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: '../assets/animation/hero-animation.json',
  rendererSettings: {
  	preserveAspectRatio: 'xMidYMid meet'
  }
});